import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import { a } from "react-router-dom";
import "../../../../Assets/css/NewMenu.css";
import Logo from "../../../../Assets/images/logo.png";
import Drawer from "@mui/material/Drawer";
import { FaBars } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"; 
import { Link } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { base_url } from "../../../../Helpers/request";
import { useDispatch, useSelector } from "react-redux";
import { currencyCode, currencyFlag, currencyIcon } from "../../../../Reducer/homeReducer";


const NewMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currencydata, setCurrencydata] = useState([]);
  const [isFixed, setIsFixed] = useState(false);
  const [carthover, setCarthover] = useState(false);
  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );


  const currencycode = useSelector(
    (state) => state.persistedReducer.home.currencycode
  );
  
  const currencyicon = useSelector(
    (state) => state.persistedReducer.home.currencyicon
  );
 
  const currencyflag = useSelector(
    (state) => state.persistedReducer.home.currencyflag
  );

  useEffect(() => {
    // fetch("https://ipinfo.io/json")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     currencyapi(data.country);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching IP information:", error);
    //   });
    currencyapi("ZA")
  }, []);


  const currencyapi = (countrycode) => {
    //---------------------------- currency api
    axios.get(base_url + "/common/currency")
      .then((res) => {
        if (res.data.status == 1) {

         
          setCurrencydata(res.data.data);
          var data = res.data.data.filter(
            (val) => countrycode == val.country_code
          );
          if (!currencycode?.length) {
            dispatch(
              currencyIcon(
                data?.length
                  ? data[0]?.currency_icon
                  : res.data.data[0]?.currency_icon
              )
            );
            dispatch(
              currencyCode(
                data?.length
                  ? data[0]?.currency_code
                  : res.data.data[0]?.currency_code
              )
            );
            dispatch(
              currencyFlag(
                data?.length
                  ? data[0]?.country_flag
                  : res.data.data[0]?.country_flag
              )
            );
          }
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //      //-----------------------------------menu
    //      axios
    //     .get(base_url + "/home/menu", {
    //       headers: postHeader,
    //     })
    //     .then((response) => {
    //       if (response.data.status == 1) {
    //         setMenudata(response.data.data);
    //       } else {
    //         toast.error(response.message, { autoClose: 3000 });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
  };

  useEffect(
    () => {
      const handleScroll = () => {
        if (window.scrollY > 1) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
    // Fixed Header
  );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  return (
    <>
      <div className={`navigation-bar ${isFixed ? "fixed" : ""}`}>
        <div className="nmain-header gridContainer">
          <Row className="basis-auto">
            <div className="logo_col col-xs">
              <a
                href="https://irresistiblediamonds.com/"
                className="custom-logo-a"
              >
                <Image src={Logo} alt="Logo" className="custom-logo" />
              </a>
            </div>
            <div className="main_menu_col col-xs">
              <div id="mainmenu_container">
                <ul className="nmain-menu">
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com">Home</a>
                  </li>
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com/about-us/">
                      Our Story
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com/products/about-diamonds/">
                      About Diamonds
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="#" className="submenudrop">
                      Diamonds
                    </a>
                    <ul className="sub-menu">
                      <li className="menu-item">
                        <a href="https://irresistiblediamonds.com/products/polished-diamonds/">
                          Polished Diamonds
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="https://irresistiblediamonds.com/products/rough-diamonds/">
                          Rough Diamonds
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="https://irresistiblediamonds.com/products/melee-diamonds/">
                          Melee Diamonds
                        </a>
                      </li>
                      <li class="menu-item"> 
                                  <Link to="/diamonds"> 
                                    Earth Mined Diamonds
                                  </Link>  
                                </li>
                                <li class="menu-item">
                                  <Link to="/diamonds/lab">  
                                    Lab-Grown Diamonds
                                  </Link>  
                                </li>
                                <li class="menu-item">
                                  <Link to="/gemstones">  
                                    Gemstones
                                  </Link>  
                                </li>
                                <li class="menu-item">
                                  <Link to="/fancy-color-diamond">  
                                    Fancy Color Diamonds
                                  </Link>   
                                </li>
                    </ul>
                  </li>
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com/products/jewellery/">
                      Jewellery
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com/products/jewellery/specials/">
                      Specials
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com/news/">News</a>
                  </li>
                  <li className="menu-item">
                    <a href="https://irresistiblediamonds.com/contact/">
                      Contact
                    </a>
                  </li>
                  <li
                    className="mesmerize-menu-cart"
                    onClick={() => {
                      history.push("/cart");
                    }}
                  >
                    <span>
                      <i className="fa fa-shopping-cart"></i>
                      {/* <span className="cart-label">Cart</span> */}
                    </span>
                    {cartlength && cartlength != "0" ? (
                      <span className="rcs_cart_badge">{cartlength}</span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      className="hamb-menubtn"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <FaBars />
                    </Button>
                    <Drawer
                    className="mui-drawers"
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      style={{ transition: "transform 0.3s linear !important" }}
                    >
                      <div className="offcanvas-top">
                        <a
                          href="https://irresistiblediamonds.com/"
                          className="custom-logo-a"
                        >
                          <Image
                            src={Logo}
                            alt="Logo"
                            className="custom-logo"
                          />
                        </a>
                      </div>

                      <div id="offcanvas-menu" class="menu-primary-container">
                        <ul id="offcanvas_menu" class="offcanvas_menu">
                          <li class="menu-item">
                            <a
                              href="https://irresistiblediamonds.com"
                              aria-current="page"
                            >
                              Home
                            </a>
                          </li>
                          <li class="menu-item">
                            <a href="https://irresistiblediamonds.com/about-us/">
                              Our Story
                            </a>
                          </li>
                          <li class="menu-item">
                            <a href="https://irresistiblediamonds.com/products/about-diamonds/">
                              About Diamonds
                            </a>
                          </li>
                          <Accordion
                            className="mnaccordion"
                            expanded={expanded}
                            onChange={handleExpansion}
                            slots={{ transition: Fade }}
                            slotProps={{ transition: { timeout: 400 } }}
                            sx={{
                              "& .MuiAccordion-region": {
                                height: expanded ? "auto" : 0,
                              },
                              "& .MuiAccordionDetails-root": {
                                display: expanded ? "block" : "none",
                              },
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <a href="#">
                                Diamonds <i class="fa fa arrow"></i>
                              </a>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul class="sub-menu">
                                <li class="menu-item">
                                  <a href="https://irresistiblediamonds.com/products/polished-diamonds/">
                                    Polished Diamonds
                                  </a>
                                </li>
                                <li class="menu-item">
                                  <a href="https://irresistiblediamonds.com/products/rough-diamonds/">
                                    Rough Diamonds
                                  </a>
                                </li>
                                <li class="menu-item">
                                  <a href="https://irresistiblediamonds.com/products/melee-diamonds/">
                                    Melee Diamonds
                                  </a>
                                </li>
                                <li class="menu-item">
                                  <Link to="/diamonds" onClick={toggleDrawer(anchor, false)}> 
                                    Earth Mined Diamonds
                                  </Link>  
                                </li>
                                <li class="menu-item">
                                  <Link to="/diamonds/lab" onClick={toggleDrawer(anchor, false)}> 
                                    Lab-Grown Diamonds
                                  </Link>  
                                </li>
                                <li class="menu-item">
                                  <Link to="/gemstones" onClick={toggleDrawer(anchor, false)}> 
                                    Gemstones
                                  </Link>  
                                </li>
                                <li class="menu-item">
                                  <Link to="/fancy-color-diamond" onClick={toggleDrawer(anchor, false)}> 
                                    Fancy Color Diamonds
                                  </Link>   
                                </li>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                          {/* <li class="menu-item">
                          <a href="javascript:void:(0);"> 
                            Diamonds <i class="fa fa arrow"></i>
                          </a>
                          <ul class="sub-menu">
                            <li class="menu-item">
                              <a href="https://irresistiblediamonds.com/products/polished-diamonds/">
                                Polished Diamonds
                              </a>
                            </li>
                            <li class="menu-item">
                              <a href="https://irresistiblediamonds.com/products/rough-diamonds/">
                                Rough Diamonds
                              </a>
                            </li>
                            <li class="menu-item">
                              <a href="https://irresistiblediamonds.com/products/melee-diamonds/">
                                Melee Diamonds
                              </a>
                            </li>
                          </ul>
                        </li> */}
                          <li class="menu-item">
                            <a href="https://irresistiblediamonds.com/products/jewellery/">
                              Jewellery
                            </a>
                          </li>
                          <li class="menu-item">
                            <a href="https://irresistiblediamonds.com/products/jewellery/specials/">
                              Specials
                            </a>
                          </li>
                          <li class="menu-item">
                            <a href="https://irresistiblediamonds.com/news/">
                              News
                            </a>
                          </li>
                          <li class="menu-item">
                            <a href="https://irresistiblediamonds.com/contact/">
                              Contact
                            </a>
                          </li>
                          <li
                            class="mesmerize-menu-cart-secondary"
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <span>
                              <i class="fa fa-shopping-cart"></i>
                              <span class="cart-label">Cart</span>
                            </span>
                            {/* {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength} 
                              </span>
                            ) : (
                              ""
                            )} */} 
                          </li>
                        </ul>
                      </div>
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div className="header-height active"></div>
    </> 
  );
};

export default NewMenu;
