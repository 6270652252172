import React from "react";
import Heading from "../ui/Heading";
import { Container } from "react-bootstrap";

const Insta = () => {
  return (
    <section className="insta-section section-space">
      <Container fluid className="showcase-container">
        <Heading>
          <h2>Social Media</h2>
          <p>
            Follow us on Instagram{" "}
            <a
              href="https://www.instagram.com/belgium_webnet/"
              className="text-dark"
              target="_blank"
            >
              <strong>@belgium_webnet</strong>
            </a>
          </p>
        </Heading>
        <iframe
          src="https://instagram.demobw.live/showcase/"
          frameborder="0"
          width="100%"
          class="insta-iframe"
        ></iframe>
      </Container>
    </section>
  );
};

export default Insta;
