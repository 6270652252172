import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Heading from "../ui/Heading";

import Red from "../Assets/img/gemstone/Red_Ruby.png";
import Blue from "../Assets/img/gemstone/Blue_Sapphire.png";
import Pink from "../Assets/img/gemstone/Pink_Sapphire.png";
import Yellow from "../Assets/img/gemstone/Yellow_Sapphire.png";
import Green from "../Assets/img/gemstone/Green_Emerald.png";
import Moissanite from "../Assets/img/gemstone/Moissanite.png";
import { Link } from "react-router-dom/cjs/react-router-dom";

const GemstoneGlamour = () => {
  return (
    <section className="gemstone-glamour section-space">
      <Container fluid className="showcase-container">
        <Row>
          <Col md={6}>
            <Heading>
              <span>Colorful Charms</span>
              <h2>Gemstone Glamour</h2>
              <p>
                Dazzle with the brilliance of gemstone Jewellery , each piece a
                testament to timeless beauty and individuality.
              </p>
            </Heading>

            <div className="gemstone-wrapper d-flex align-items-center justify-content-around">
              <div className="item">
                <Image src={Red} alt="Red Ruby" />
                <p>Red Ruby</p>
              </div>
              <div className="item">
                <Image src={Blue} alt="Blue Sapphire" />
                <p>Blue Sapphire</p>
              </div>
              <div className="item">
                <Image src={Pink} alt="Pink Sapphire" />
                <p>Pink Sapphire</p>
              </div>
              <div className="item">
                <Image src={Yellow} alt="Yellow Sapphire" />
                <p>Yellow Sapphire</p>
              </div>
              <div className="item">
                <Image src={Green} alt="Green Emerald" />
                <p>Green Emerald</p>
              </div>
              <div className="item">
                <Image src={Moissanite} alt="Moissanite" />
                <p>Moissanite</p>
              </div>
            </div>

            <div className="text-center pt-3">
              <Link to="/gemstones" className="btn outline-base-btn large">
                Explore Now
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default GemstoneGlamour;
