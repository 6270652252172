import React from "react";
import Heading from "../ui/Heading";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";

import Ring from "../Assets/img/Rings.jpg";
import Bracelet from "../Assets/img/Bracelets.jpg";
import Earring from "../Assets/img/Earrings.jpg";
import Necklace from "../Assets/img/Necklaces.jpg";

const ExploreCollection = () => {
  return (
    <section className="explore-collection section-space">
      <Container fluid className="showcase-container">
        <Heading>
          <h2>Shop By Collection</h2>
          <p>Explore Jewellery Collections by Style and Make a Statement.</p>
        </Heading>

        <Row>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/fashion-rings" className="img-box">
                <Image src={Ring} alt="Ring" />
              </Link>
              <Link to="/jewelry/fashion-rings">Rings</Link>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/bracelets" className="img-box">
                <Image src={Bracelet} alt="Bracelet" />
              </Link>
              <Link to="/jewelry/bracelets">Bracelets</Link>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/earrings" className="img-box">
                <Image src={Earring} alt="Earring" />
              </Link>
              <Link to="/jewelry/earrings">Earrings</Link>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <div className="item text-center">
              <Link to="/jewelry/necklaces" className="img-box">
                <Image src={Necklace} alt="Necklace" />
              </Link>
              <Link to="/jewelry/necklaces">Necklaces</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ExploreCollection;
